import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"update"},[_c('h1',{staticClass:"subtitle-1 grey--text pa-8"},[_vm._v("update page")]),_c(VForm,{ref:"form",staticClass:"px-3"},[_c(VTextField,{attrs:{"label":"Title","prepend-inner-icon":"mdi-folder","rules":_vm.inputRules},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c(VTextarea,{attrs:{"label":"Information","prepend-inner-icon":"mdi-account-edit-outline","rules":_vm.inputRules},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}),_c(VTextField,{attrs:{"label":"Name","prepend-inner-icon":"mdi-account-badge","rules":_vm.NameRules},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c(VSelect,{attrs:{"items":_vm.items,"rules":[v => !!v || 'Item is required'],"label":"Status","required":""},model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}}),_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Due date","prepend-icon":"mdi-calendar","readonly":"","rules":_vm.inputRules},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}])},[_c(VDatePicker,{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1),_c(VBtn,{staticClass:"success mx-0 mt-3",attrs:{"text":"","loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v("Update project")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }