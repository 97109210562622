import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"upload"},[_c(VSheet,{staticClass:"d-flex align-center justify-center flex-wrap text-center mx-auto",attrs:{"elevation":"4","height":"250","rounded":"","max-width":"800","width":"100%"}},[_c('div',[_c('h2',{staticClass:"text-h4 font-weight-black text-orange mb-4"},[_vm._v("New Function!")]),_c('div',{staticClass:"text-h5 font-weight-medium"},[_vm._v(" You can upload file here to the cloud storage ")])])]),_c('h2',{staticClass:"font-weight-bold ml-2"},[_vm._v("Upload File Here:")]),_c(VFileInput,{ref:"fileInput",attrs:{"multiple":"","label":"File input"},on:{"change":_vm.uploadFile}}),(_vm.uploadProgress !== null)?_c(VProgressLinear,{attrs:{"value":_vm.uploadProgress}}):_vm._e(),_c(VSnackbar,{attrs:{"timeout":4000,"top":""},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"color":"green","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('span',[_vm._v("Upload success")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }