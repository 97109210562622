import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"markdown"},[_c('h1',{staticClass:"subtitle-1 grey--text pa-8"},[_vm._v("Markdown")]),_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"label":"File title"},model:{value:(_vm.titleInput),callback:function ($$v) {_vm.titleInput=$$v},expression:"titleInput"}})],1)],1),_c(VRow,{staticClass:"input_content"},[_c(VCol,{attrs:{"cols":"6"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.markdownContent),expression:"markdownContent"}],staticStyle:{"height":"400px"},attrs:{"placeholder":"Enter here"},domProps:{"value":(_vm.markdownContent)},on:{"input":[function($event){if($event.target.composing)return;_vm.markdownContent=$event.target.value},_vm.renderMarkdown]}})]),_c(VCol,{attrs:{"cols":"6"}},[_c('div',{staticClass:"new_content",staticStyle:{"height":"400px"},domProps:{"innerHTML":_vm._s(_vm.renderedHTML)}})])],1),_c(VRow,[_c(VCol,{staticClass:"center-align",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"button",on:{"click":_vm.exportToPDF}},[_vm._v("Export to PDF")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }