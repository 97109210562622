import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAppBarTitle } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VThemeProvider } from 'vuetify/lib/components/VThemeProvider';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',[_c(VAppBar,{attrs:{"flat":"","app":""}},[_c(VAppBarNavIcon,{staticClass:"grey--text",on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c(VAppBarTitle,{staticClass:"text-uppercase grey--text"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Notebook")])]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.toggleTheme}},[_c(VIcon,[_vm._v(_vm._s(_vm.isDarkTheme ? 'mdi-white-balance-sunny' : 'mdi-weather-night'))])],1)],1),_c(VThemeProvider,{attrs:{"dark":_vm.darkTheme}},[_c(VNavigationDrawer,{attrs:{"app":"","color":"#F4F5F9"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VCard,{staticClass:"pa-8",attrs:{"height":"200px","align":"center","color":"#ECEFF1"}},[_c(VBadge,{staticClass:"mb-8",attrs:{"bordered":"","bottom":"","color":"green","dot":"","offset-x":"10","offset-y":"10","elevation":"18"}},[_c(VAvatar,{attrs:{"size":"100"}},[_c(VImg,{attrs:{"src":"https://cdn.jsdelivr.net/gh/zhangjjde-haobaba/imageBed@main//avatar-1.png"}})],1)],1)],1),_c(VList,_vm._l((_vm.links),function(link){return _c(VListItem,{key:link.text,attrs:{"router":"","to":link.route,"color":"#90A4AE"}},[_c(VListItemAction,[_c(VIcon,{staticClass:"black--text font-weight-medium"},[_vm._v(_vm._s(link.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"black--text font-weight-medium"},[_vm._v(" "+_vm._s(link.text)+" ")])],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }